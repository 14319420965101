<template>
  <div class="AberrationAnalyze">
    <h3>像差分析仪</h3>
    <div style="text-align: left;"> <el-button type="info">读取文件</el-button></div>
    <div class="tp">
      <UPfile :type="'WI'"></UPfile>
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :file-list="fileLists"-->
<!--          :on-change="sc"-->
<!--          ref="uptqs"-->
<!--          :auto-upload="false">-->
<!--&lt;!&ndash;        <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--        <div>上传图片</div>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--&lt;!&ndash;          <i class="el-icon-download"></i>&ndash;&gt;-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_WI_Remark">
      </el-input>
    </div>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "AberrationAnalyze",
  data() {
    return {
      postData: {},
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,
      fileLists: []
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upAberrationAnalyze', n)
      },
      deep: true
    }
  },
  created() {
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
     this._api.eyeTest.getAberrationAnalyze()
       .then(res => {
         if (res.GetListResult && res.GetListResult.length > 0) {
           // console.log(this.postData)
         }
       })

    this._api.publicApi.getImgList('WI')
        .then(res => {
          let arr = []
          if (res.GetListResult && res.GetListResult.length > 0) {
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                name: res.GetListResult[i].UFId,
                url: res.GetListResult[i].FullURL,
              })
            }
            this.fileLists = arr
          }
        })
  },
  methods: {
    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'WI',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },
    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptqs.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
.AberrationAnalyze {
  width: 90%;
  margin: 0 auto;
}
.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
</style>
